import type { VueGtmUseOptions } from '@gtm-support/vue-gtm'
import router from '@/router'

function getGtmConfig(): VueGtmUseOptions {
  const environment = import.meta.env.VITE_ENVIRONMENT || 'local'

  return {
    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER || 'GTM-XXXXXXXXX',
    vueRouter: router,
    debug: import.meta.env.VITE_DEBUG === 'true' || environment === 'local',
  }
}

const gtmConfig: VueGtmUseOptions = getGtmConfig()

export default gtmConfig
