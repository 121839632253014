import type { RecaptchaOptionsInput } from 'vue-recaptcha'
import api from '@api'

function getRecaptchaConfig(): RecaptchaOptionsInput {
  return {
    v3SiteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY || 'xxx',
  }
}

export function verifyRecaptcha(token: string): Promise<{ success: boolean; score: number }> {
  return api.captcha.captchaVerifyCreate(token).then(response => response.data)
}

const recaptchaConfig = getRecaptchaConfig()

export default recaptchaConfig
