<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal'
import { useRecaptchaProvider } from 'vue-recaptcha'
import { useHead } from '@unhead/vue'

useRecaptchaProvider()

useHead({
  title: 'Bidding Platform by Transportly',
})
</script>

<template>
  <RouterView />
  <ModalsContainer />
</template>
