import { acceptHMRUpdate, defineStore } from 'pinia'
import api from '@api'
import type {
  Auction,
  AuctionChangePaymentMethod,
  AuctionParticipantStatusEnum,
  AuctionTakeInstantOrder, AuctionsExportRetrieveParams, AuctionsListParams,
  PatchedAuctionUpdate } from '@apiTypes'
import type { InstantOrderRequest, InstantOrderResponse } from '@/types/instant-order-data'

export const useAuctionsStore = defineStore('auctions', {
  state: () => ({
    instantOrderRequest: undefined as InstantOrderRequest | undefined,
    instantOrderResponse: undefined as InstantOrderResponse | undefined,
  }),
  actions: {
    setInstantOrderData(request: InstantOrderRequest, response: InstantOrderResponse) {
      this.instantOrderRequest = request
      this.instantOrderResponse = response
    },
    popInstantOrderRequest() {
      const instantOrderRequest = this.instantOrderRequest

      this.instantOrderRequest = undefined

      return instantOrderRequest
    },
    popInstantOrderResponse() {
      const instantOrderResponse = this.instantOrderResponse

      this.instantOrderResponse = undefined

      return instantOrderResponse
    },
    fetchAuctions(query: AuctionsListParams) {
      return api.auctions.auctionsList(query).then(response => response.data)
    },
    fetchAuction(id: number) {
      return api.auctions.auctionsRetrieve(id).then(response => response.data)
    },
    fetchTransport(id: number) {
      return api.transports.transportsRetrieve(id).then(response => response.data)
    },
    fetchCarrierAuction(hash: string, carrierHash: string) {
      return api.byHash.byHashCarriersAuctionsRetrieve(carrierHash, hash, { skipAuthToken: true }).then(response => response.data)
    },
    publishAuction(id: number) {
      return api.auctions.auctionsPublishCreate(id, {} as Auction)
    },
    takeInstantOrder(id: number, data: AuctionTakeInstantOrder) {
      return api.auctions.auctionsTakeInstantOrderCreate(id, data)
    },
    changePaymentMethod(id: number, data: AuctionChangePaymentMethod) {
      return api.auctions.auctionsChangePaymentMethodCreate(id, data)
    },
    cancelAuction(id: number) {
      return api.auctions.auctionsCancelCreate(id, {} as Auction)
    },
    updateCarriers(id: number, participantsIds: number[]) {
      return api.auctions.auctionsPartialUpdate(id, { invitedParticipants: participantsIds })
    },
    extendDuration(id: number, duration: string) {
      return api.auctions.auctionsPartialUpdate(id, { duration })
    },
    updateAuction(id: number, data: PatchedAuctionUpdate) {
      return api.auctions.auctionsPartialUpdate(id, data)
    },
    updateCarrierStatus(hash: string, carrierHash: string, status: AuctionParticipantStatusEnum) {
      return api.byHash.byHashCarriersAuctionsStatusCreate(carrierHash, hash, { status })
    },
    deleteAuction(id: number) {
      return api.auctions.auctionsDestroy(id)
    },
    exportAuctions(params: AuctionsExportRetrieveParams) {
      return api.auctions.auctionsExportRetrieve(params, { format: 'blob' })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuctionsStore, import.meta.hot))
