import DOMPurify from 'dompurify'
import type { DirectiveBinding } from 'vue'

export const sanitizedHtml = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = sanitizeHTML(binding.value)
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = sanitizeHTML(binding.value)
  },
}

function sanitizeHTML(html: string) {
  return DOMPurify.sanitize(html)
}
