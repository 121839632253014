import * as Sentry from '@sentry/vue'
import { AxiosError } from 'axios'
import type { ErrorEvent, EventHint } from '@sentry/vue'
import type { Options } from '@sentry/vue/build/types/types'
import router from '@/router'

function shouldSkipErrorTracking(hint: EventHint): boolean {
  if (hint.originalException instanceof AxiosError) {
    const { response } = hint.originalException
    if (response?.status === 401)
      return true
  }

  return false
}

function getSentryConfig(): Partial<Options> {
  const commonConfig = {
    dsn: import.meta.env.VITE_SENTRY_DNS || '',
    beforeSend: (event: ErrorEvent, hint: EventHint) => shouldSkipErrorTracking(hint) ? null : event,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  }

  if (import.meta.env.DEV) {
    return {
      ...commonConfig,
      environment: 'local',
      debug: true,
      tracePropagationTargets: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS?.split(';') || ['localhost'],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      tracesSampleRate: 0,
    }
  }

  return {
    ...commonConfig,
    environment: import.meta.env.VITE_ENVIRONMENT || 'local',
    debug: import.meta.env.VITE_DEBUG === 'true',
    replaysSessionSampleRate: Number.parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0.1'),
    replaysOnErrorSampleRate: Number.parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),
    tracesSampleRate: Number.parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || '0.25'),
  }
}

const sentryConfig: Partial<Options> = getSentryConfig()

export default sentryConfig
