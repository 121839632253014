import { acceptHMRUpdate, defineStore } from 'pinia'
import api from '@api'
import type {
  TransportDetail,
  TransportDetailByHash,
  TransportUpdateStatus, Transports, TransportsListParams,
} from '@apiTypes'

export const useTransportsStore = defineStore('transports', {
  actions: {
    fetchTransports(query: TransportsListParams) {
      return api.transports.transportsList(query).then(response => response.data)
    },
    fetchTransport(id: number) {
      return api.transports.transportsRetrieve(id).then(response => response.data)
    },
    fetchTransportDetails(id: number) {
      return api.transports.transportsTransportDetailsRetrieve(id)
    },
    updateTransport(id: number, params: Transports.TransportsPartialUpdate.RequestBody) {
      return api.transports.transportsPartialUpdate(id, params)
    },
    updateTransportDetails(id: number, data: TransportDetail) {
      return api.transports.transportsTransportDetailsCreate(id, data)
    },
    updateTransportStatus(id: number, data: TransportUpdateStatus) {
      return api.transports.transportsStatusCreate(id, data)
    },
    deleteTransport(id: number) {
      return api.transports.transportsDestroy(id)
    },
    fetchCarrierTransportDetails(hash: string, carrierHash: string) {
      return api.byHash.byHashCarriersAuctionsTransportDetailsRetrieve(carrierHash, hash, { skipAuthToken: true })
    },
    updateCarrierTransportDetails(hash: string, carrierHash: string, data: TransportDetailByHash) {
      return api.byHash.byHashCarriersAuctionsTransportDetailsCreate(carrierHash, hash, data, { skipAuthToken: true })
    },
    validateFirstStep(data: Transports.TransportsValidateFirstStepCreate.RequestBody) {
      return api.transports.transportsValidateFirstStepCreate(data)
    },
    validateSecondStep(data: Transports.TransportsValidateSecondStepCreate.RequestBody) {
      return api.transports.transportsValidateSecondStepCreate(data)
    },
    validateThirdStep(data: Transports.TransportsValidateThirdStepCreate.RequestBody) {
      return api.transports.transportsValidateThirdStepCreate(data)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTransportsStore, import.meta.hot))
