import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { isUserLoggedIn } from '@/auth/utils'
import { extendRouteWithGuard, switchWorkspaceGuard } from '@/router/guards'
import { publicPages } from '@/router/public-pages'
import Empty from '@/components/Empty.vue'

const nestedRoutesPrefixes = ['transport', 'request', 'carrier', 'address', 'settings']

const nonPublicRoutes = setupLayouts(routes).map(route => ({
  ...route,
  path: route.path.startsWith('/') ? route.path.slice(1) : route.path,
}))
  .filter(route => nestedRoutesPrefixes.some(nested => route.path.startsWith(nested)))

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/w/:workspaceId',
      component: Empty,
      beforeEnter: switchWorkspaceGuard,
      children: nonPublicRoutes,
    },
    ...setupLayouts(routes).map(route => extendRouteWithGuard(route)),
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(to => {
  const authRequired = !publicPages.reduce((a, b) => a || RegExp(b).test(<string>to.name), false)
  if (authRequired && !isUserLoggedIn())
    return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
})

// fixes errors on dynamically import modules https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', event => {
  console.error('vite:preloadError', event)
  window.location.reload()
})

export default router
