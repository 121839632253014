import type { StorageLike } from '@vueuse/core/index'

function createStorage(): StorageLike {
  if (typeof localStorage !== 'undefined' && localStorage !== null)
    return localStorage

  const values: Record<string, string> = {}

  return {
    setItem: (key, value) => {
      values[key] = value
    },
    getItem: (key: string) => {
      return values[key] || null
    },
    removeItem: (key: string) => {
      delete values[key]
    },
  }
}

const storage: StorageLike = createStorage()

export default storage
